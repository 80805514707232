<template>
	<div>
		<div class="footerbg" v-if="!nos">
			<div>
					<p>智能化客服服务，0成本体验小到科技带来的全功能体验</p>
					<div class="btn" @click="tg('https://kefu.51xd.cn/register')">
						立即试用
					</div>
			</div>
		</div>
		<div class="bottom">
			<div class="st">
				
				<div class="ts-z">
					<p>关于我们</p>
					<div>
						<span style="margin-right: 0.16rem;" @click="tg('/m/gywm',1)">公司简介</span>
						<span  @click="tg('/m/zsdl',1)">招商代理</span>
					</div>
				</div>
				
				<div class="ts-z">
					<p>产品</p>
					<div>
						<span @click="to(1)">操作手册</span>
					</div>
				</div>
			</div>
			<div class="ts-z" style="margin-right: 0;">
				<p>联系我们</p>
				<div class="tzx">
					<span>公司名称：湖南小到科技有限公司</span>
					<span>电话：17375842106</span>
					<span>邮箱：tuyu107@163.com</span>
					<span>地址：湖南省长沙市岳麓区达美美立方1栋1825</span>
				</div>
			</div>
			<div class="codeq center">
				 <div class="center">
					<img src="../assets/qrcode_for_gh_c57f55f1c42c_258.jpg"/>
					<p>微信公众号-小到科技</p>
				 </div>
			</div>
			
		</div>
		<div class="line-info">
		
			<p>版权所有 Copyright @copy; 2019 湖南小到科技有限公司, All Rights Reserved 备案号：湘ICP备19006810号-2</p>
		
		</div>
		
	</div>
</template>

<script>
	export default{
		name:'footerS',
		props:{
			nos:{
				type:String,
				default:''
			}
		},
		methods:{
			to(t) {
			  var url = t == 0 ? 'https://img.51xd.cn/download/xdkf.exe' : 'https://img.51xd.cn/download/小到网站客服操作手册.pdf';
			  var referLink = document.createElement('a');
			  referLink.href = url;
			  referLink.target = '_blank'
			  if (/MSIE (\d+\.\d+);/.test(navigator.userAgent) || /MSIE(\d+\.\d+);/.test(navigator.userAgent)) {
			    document.body.appendChild(referLink);
			  }
			  referLink.click();
			}
		
		}
	}
</script>

<style lang="scss" scoped>
	.line-info{
		box-sizing: border-box;
		padding-top: 0.16rem;
		border-top: 1.5px solid #D3D3D4;
		margin:auto 0.16rem ;
		font-weight: 400;
		font-size: 0.14rem;
		line-height: 0.21rem;
		text-align: center;
		color: #60666F;
	}
	.codeq{
		margin-top: 0.1rem;
		>div{
			flex-direction: column;
			img{
				width: 0.88rem;
				margin-bottom: .1rem;
			}
		}
	}
	.bottom{
		.tzx{
			display: flex;
			flex-direction: column;
			span{
				margin-bottom: 0.16rem;
			}
		}
		padding: 0.32rem 0.16rem;
		.st{
			display: flex;
			margin-bottom: 0.24rem;
		}
		.ts-z{
			margin-right: 0.32rem;
			>p{
				font-weight: 400;
				font-size: 0.16rem;
				line-height: 0.24rem;
				color: #182F50;
				position: relative;
				padding-bottom: 0.08rem;
				margin-bottom: 0.24rem;
				&+div{
					font-weight: 400;
					font-size: 0.14rem;
					line-height: 0.21rem;
					color: #60666F;
					span{cursor: pointer;}
				}
				&::after{
					position: absolute;
					content: '';
					width: 0.2rem;
					height: 2px;
					background: #D3D3D4;
					left: 0;
					bottom: 0;
				}
			}
		}
	}
	
	.footerbg{

		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		background: url('../assets/m/sy/footerbg.png') no-repeat;
		background-size: cover;
		width: 100%;
		height: 2.5rem;
		padding: 0.16rem;
		color: #fff;
		p{
			font-weight: 500;
			font-size: 0.16rem;
			line-height: 0.24rem;
			text-align: center;
		}
		.btn{
			width: 0.96rem;
			margin: 0 auto;
			height: 0.4rem;
			background: #FFFFFF;
			border-radius: 6px;
			font-weight: 500;
			text-align: center;
			font-size: 0.16rem;
			line-height: 0.4rem;
			color: #3A87FC;
			margin-top: 0.12rem;
			cursor: pointer;
		}
	}
	
</style>