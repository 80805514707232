import Vue from 'vue'
import Router from 'vue-router'
import { IsPC } from '@/common'
Vue.use(Router)
import navS from '@/components/navS'
import navM from '@/components/navM'
var IsPc=IsPC();
export const constantRoutes =[
	{
		path: '/',
		component: IsPc?navS:navM,
		children: [{
			path: '',
			name: '首页',
			component: () =>import('@/views/index'),
			meta: {
				title: '首页'
			}
		},
		{
			path: 'm',
			name: '首页m',
			component: () =>import('@/views/m'),
			meta: {
				title: '首页'
			}
		},
		{
			path: 'm/product',
			name: '在线客服m',
			component: () =>import('@/views/m/product'),
			meta: {
				title: '在线客服'
			}
		},
		{
			path: 'm/product/ai',
			name: 'ai客服m',
			component: () =>import('@/views/m/ai'),
			meta: {
				title: 'ai客服'
			}
		},
		{
			path: 'm/anli',
			name: '案例m',
			component: () =>import('@/views/m/anli'),
			meta: {
				title: '案例'
			}
		},
		{
			path: 'm/zsdl',
			name: '招商代理m',
			component: () =>import('@/views/m/zsdl'),
			meta: {
				title: '招商代理'
			}
		},
		{
			path: 'm/gywm',
			name: '关于我们m',
			component: () =>import('@/views/m/gywm'),
			meta: {
				title: '关于我们'
			}
		}
	 ]
	},
	{
		path: '/product',
		component: navS,
		children: [{
				path: '',
				name: '在线客服',
				component: () => import('@/views/product'),
				meta: {
					title: '在线客服'
				}
			},
			{
				path: 'ai',
				name: 'Ai客服',
				component: () => import('@/views/product/ai'),
				meta: {
					title: 'Ai客服'
				}
			}
		]
	},
	{
		path: '/anli',
		component: navS,
		children: [{
			path: '',
			name: '案例',
			component: () => import('@/views/anli'),
			meta: {
				title: '案例'
			}
		}]
	},
	{
		path: '/zsdl',
		component: navS,
		children: [{
			path: '',
			name: '招商代理',
			component: () => import('@/views/zsdl'),
			meta: {
				title: '招商代理'
			}
		}]
	},
	{
		path: '/gywm',
		component: navS,
		children: [{
			path: '',
			name: '关于我们',
			component: () => import('@/views/gywm'),
			meta: {
				title: '关于我们'
			}
		}]
	},
	{
		path: '/404',
		component: () => import('@/views/404'),
	},

	{
		path: '*',
		redirect: '/404',
		hidden: true
	}

]

const createRouter = () => new Router({
	mode: 'history', // require service support
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}

export default router
