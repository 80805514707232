<template>

	<el-row class="banner-bg" :style="{backgroundImage:url}">
		<div class="con">
			<p>{{p}}</p>
			<div>{{con}}</div>
			<div><el-button type="primary" v-if="con" @click="tg('https://kefu.51xd.cn/register')">立即试用</el-button></div>
		</div>
		
	</el-row>

</template>

<script>
 export default{
	 props:{
		 p:{
			 type:String,
			 default:''
		 },
		 con:{
			 type:String,
			 default:''
		 },
		 url:{
			 type:String,
			 default:''
		 }
	 }
	 
 }
</script>

<style lang="scss" scoped>
	.banner-bg{
		width: 100%;
		height: 464px;
		background-repeat: round;
		.con{
			width: 1200px;
			margin: 0 auto;
			padding-top: 132px;
		}
		p{
			
			font-weight: 700;
			font-size: 40px;
			line-height: 60px;
			letter-spacing: 0.1em;
			color: #182F50;
			margin-bottom: 10px;
			&+div{
				width: 531px;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.1em;
				color: #60666F;
				margin-bottom: 55px;
			}
		}
		
			}
</style>