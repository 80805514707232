<template>
	<div>
		<div class="m-nav">
			<img class="logo" src="/logo.png" style="width: 1.2rem;" @click="tg('/m',1)">
			<img src="../assets/m/menu.svg" @click="show=!show"  :class="show?'se':''"/>
			<el-collapse-transition>
				<div class="menuFloat" v-show="show">
					    <el-menu
						   router
						  :default-active="activeIndex" 
					      class="el-menu-vertical-demo">
						  <el-menu-item index="/m">
						    <span slot="title">首页</span>
						  </el-menu-item>
					      <el-submenu  index="/m/product">
					        <template slot="title">
					          <span>产品</span>
					        </template>
					        <el-menu-item-group>
					          <el-menu-item index="/m/product">在线客服</el-menu-item>
					          <el-menu-item index="/m/product/ai">AI客服</el-menu-item>
					        </el-menu-item-group>
					      </el-submenu>
							<el-menu-item index="/m/anli">
							  <span slot="title">案例</span>
							</el-menu-item>	
							<el-menu-item index="/m/zsdl">
							  <span slot="title">招商代理</span>
							</el-menu-item>	
							<el-menu-item index="/m/gywm">
							  <span slot="title">关于我们</span>
							</el-menu-item>	
					    </el-menu>
				</div>
			</el-collapse-transition>
		</div>
		<div style="height: 0.56rem;"></div>

		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
				activeIndex:this.$route.path,
			}
		},
		watch:{
			'$route.path'(n,o){
				this.show=false
			}
		}
	
	}
</script>

<style lang="scss" scoped>
	.se{
		transform: rotate(90deg);
	}
	.menuFloat {
		.t{
			border-bottom: 1px solid #dcdfe6;
		}
		position: fixed;
		top: 0;
		width: 100%;
		left: 0;
		top: 0.56rem;
		overflow-y: auto;
		background: #fff;
	}

	.m-nav {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background: #fff;
		height: 0.56rem;
		z-index: 50;
		padding: 0 0.16rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
</style>
