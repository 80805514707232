<template>
  <div style="min-width: 1200px;">
  <div class="nav" v-show="navshow">
  	<div class="container">
  		<img class="logo" src="/logo.png" alt="" @click="tg('/',1)" style="cursor: pointer;width: 138px;">
  		
  		<div class="menuList">
  			<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" router>
				
  			  <el-menu-item index="/" >首页</el-menu-item>
  			  <el-submenu index="/product">
  			    <template slot="title">产品</template>
  			    <el-menu-item index="/product">在线客服</el-menu-item>
  			    <el-menu-item index="/product/ai">AI客服</el-menu-item>
  	
  			  </el-submenu>
  			  <el-menu-item index="/anli">案例</el-menu-item>
			    <el-menu-item index="/zsdl">招商代理</el-menu-item>
  			  <el-menu-item index="/gywm">关于我们</el-menu-item>
  			</el-menu>
  		</div>
  		<div class="right-con">
			<div class="rx">
				<el-row type="flex" align="middle"><img src="../assets/svg/rx.svg" style="margin-right: 8px;"/>快速咨询热线</el-row>
				<el-row style="color: #3A87FC;line-height: 24px">17375842106</el-row>
				
			</div>
			<span class="nav-log" @click="tg('https://kefu.51xd.cn/')">登录</span>
			<span class="nav-btn" @click="tg('https://kefu.51xd.cn/register')">免费注册</span>
		</div>
   </div>
  	 </div>
	 <div style="height: 64px;"></div>

  	 <router-view id="con" :initTop="initTop" />

  </div>
  
</template>

<script>

export default {
  name: 'navS',
    data() {
        return {
          activeIndex: this.$route.path,
		  navshow:true,
		  initTop:0
        };
      },
	mounted() {
		window.onscroll = (e)=>{
			let t = document.documentElement.scrollTop || document.body.scrollTop;
			let scr=t-this.initTop
			this.initTop=t
			let dir=1;
			dir=scr<=0?0:1;
			if(dir==0){
				this.navshow=true
			}else{
				this.navshow=t<500
			}
			
		}
	
	
	}
}
</script>

<style scoped lang="scss">
	.right-con{
		display: flex;
		align-items: center;
		.rx{
			margin-right: 42px;
			margin-left: 54px;
		}
		.nav-log{
			border: 1px solid #3A87FC;
			border-radius: 4px;
			font-weight: 400;
			font-size: 16px;
			color: #3A87FC;
			padding: 0 20px;
			height: 34px;
			line-height: 34px;
			cursor: pointer;
		}
		.nav-btn{
			background: #3A87FC;
			border-radius: 4px;	
			height: 36px;
			line-height: 36px;
			padding: 0 12px;
			font-weight: 400;
			font-size: 16px;
			color: #FFFFFF;
			margin-left: 16px;
		}
	}
.container {
	width: 1200px;
	margin: 0px auto;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.menuList{
	margin-left: 77px;

	height: 100%;
	::v-deep .el-menu.el-menu--horizontal{
		border: none;
		height: 100%;
		color: #182F50;
		>.el-menu-item,>.el-submenu{
			color: #182F50;
			height: 100%;
			padding: 0;
			margin-right: 56px;
			font-size: 16px;
			.el-submenu__title{color: #182F50;padding: 0;font-size: 16px;height: inherit;}
		}
	}
}




</style>
