import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/styles/index.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import footer from '@/components/footer'
import footerM from '@/components/footerM'
import banner from '@/components/banner'
import bannerM from '@/components/bannerM'
import '@/permission' 
import { IsPC } from '@/common'
Vue.prototype.tg=(url,t)=>{
	if(t){
		window.location.href = url;
	}else{
		window.open(url,'_blank');      
	}
	
}
Vue.component('bannerM',bannerM)
Vue.component('banner',banner)
Vue.component('footerS',footer)
Vue.component('footerM',footerM)
Vue.config.productionTip = false;
Vue.use(ElementUI)
new Vue({
  render: h => h(App),
  router,
  mounted () {
      document.dispatchEvent(new Event('render-event'))
	  if(!IsPC()){
		  (function (doc, win) {
		    var docEl = doc.documentElement,
		  	resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
		  	recalc = function () {
		  	  var clientWidth = docEl.clientWidth;
		  	  if (!clientWidth) return;
		  		docEl.style.fontSize = 100 * (clientWidth / 375) + 'px'; 
		  	};
		    if (!doc.addEventListener) return;
		    win.addEventListener(resizeEvt, recalc, false);
		    doc.addEventListener('DOMContentLoaded', recalc, false);
		  })(document, window);
	  }

	  
    }
}).$mount('#app')
