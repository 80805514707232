<template>

	<el-row  v-if="!con">
		<slot></slot>
	</el-row>
	<el-row class="banner-bg" :style="{backgroundImage:url}" v-else>
		<div class="con">
			<p>{{p}}</p>
			<div>{{con}}</div>
			<div class="center"><el-button type="primary" size="small" v-if="con" @click="tg('https://kefu.51xd.cn/register')">立即试用</el-button></div>
		</div>
		
	</el-row>
</template>

<script>
 export default{
	 props:{
		 p:{
			 type:String,
			 default:''
		 },
		 con:{
			 type:String,
			 default:''
		 },
		 url:{
			 type:String,
			 default:''
		 }
	 }
	 
 }
</script>

<style lang="scss" scoped>
	.banner-bg{
		width: 100%;
		height: 3.85rem;
		background-repeat: round;
		display: flex;
		padding: 0.32rem 0.16rem;
		text-align: center;
		.con{
			
		}
		p{
			text-align: center;
			font-weight: 700;
			font-size: 0.2rem;
			letter-spacing: 0.1em;
			color: #182F50;
			margin-bottom: 0.1rem;
			&+div{
				font-weight: 400;
				font-size: 0.12rem;
				line-height: 0.15rem;
				letter-spacing: 0.1em;
				color: #60666F;
				margin-bottom: 0.16rem;
			}
		}
		
			}
</style>