<template>
	<div class="container">
		<div class="blue-bg" v-if="!nos">
			<div class="con">
				<p>智能化客服服务，0成本体验小到科技带来的全功能体验</p>
				<el-button @click="tg('https://kefu.51xd.cn/register')">立即试用</el-button>
			</div>

		</div>




		<div class="bottom">
			<div class="top">

				<div>
					
					<div class="wxgzh">
						<img class="logo" src="/logo.png" alt="" style="width: 138px;">
						<img src="@/assets/qrcode_for_gh_c57f55f1c42c_258.jpg" class="qcode" >
						<p>微信公众号-小到科技</p>
					</div>

				</div>

				<div class="item">
					<p>产品</p>
					<div class="a-tg"  @click="to(0)">客户端下载</div>
					<div class="a-tg"  @click="to(1)">操作手册</div>
				</div>

				<div class="item">
					<p>关于我们</p>
					<div class="a-tg" @click="tg('/gywm',1)">公司简介</div>
					<div class="a-tg"  @click="tg('/zsdl',1)">招商代理</div>

				</div>

				<div class="item">
					<p>联系我们</p>
					<div class="a-tg">公司名称：湖南小到科技有限公司</div>
					<div class="a-tg">电话：17375842106</div>
					<div class="a-tg">邮箱：tuyu107@163.com</div>
					<div class="a-tg">地址：湖南省长沙市岳麓区达美美立方1栋1825</div>
				</div>

			</div>
			<div class="line-info">

				<p>版权所有 Copyright @copy; 2019 湖南小到科技有限公司, All Rights Reserved 备案号：湘ICP备19006810号-2</p>

			</div>
		</div>

	</div>
</template>

<script>
	export default{
		name:'footerS',
		props:{
			nos:{
				type:String,
				default:''
			}
		},
		methods:{
			to(t) {
			  var url = t == 0 ? 'https://img.51xd.cn/download/xdkf.exe' : 'https://img.51xd.cn/download/小到网站客服操作手册.pdf';
			  var referLink = document.createElement('a');
			  referLink.href = url;
			  referLink.target = '_blank'
			  if (/MSIE (\d+\.\d+);/.test(navigator.userAgent) || /MSIE(\d+\.\d+);/.test(navigator.userAgent)) {
			    document.body.appendChild(referLink);
			  }
			  referLink.click();
			}
		
		}
	}
</script>

<style lang="scss" scoped>
	.bottom{
		width: 1200px;
		margin: 40px auto;
		.top{
			display: flex;
			margin-bottom: 96.5px;
			justify-content: space-around;
			.wxgzh{
				display: flex;
				flex-direction: column;
				align-items: center;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				color: #182F50;

				.qcode{
					width: 88px;
					margin-top: 50px;
					margin-bottom: 12px;
				}
				
			}
			.item{
					p{
					position: relative;
					color: #182F50;
					margin-bottom: 32px;
					&::after{
						position: absolute;
						content: '';
						height: 1.5px;
						width: 20px;
						left: 0;
						bottom: -8px;
						background: #D3D3D4;
					}
				}
				.a-tg{
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
					color: #60666F;
					margin-bottom: 10px;
					cursor: pointer;
					&:hover{
						color: #3A87FC;
					}
				}
			}
		}
	}
	.line-info {
		width: 1000px;
		margin: 0 auto;
		border-top: 1.5px solid #D3D3D4;
		font-weight: 400;
		font-size: 14px;
		color: #60666F;
		padding-top: 24.5px;
		p {
			text-align: center;
		}
	}

	.blue-bg {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 340px;
		background: url('../assets/bgfooter.png') no-repeat;
		background-size: cover;
		.con{
			display: flex;
			justify-items: center;
			align-items: center;
			flex-direction: column;
			.el-button{
				font-size: 24px;
				padding: 12px 40px;
				line-height: 36px;
				color: #3A87FC;
				border-radius: 10px;
			}
		}
		p{
			font-weight: 500;
			font-size: 24px;
			color: #FFFFFF;
			margin-bottom: 24px;
		}
	}
</style>
