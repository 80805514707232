import router from './router'
import getPageTitle from '@/common/get-page-title'
import { IsPC } from '@/common'
var IsPc=IsPC();
router.beforeEach((to, from, next) => {
  //设置页面标题
  document.title = getPageTitle(to.meta.title);
  if(!IsPc&&to.path.indexOf('/m')<0){
	  
	    next({ path: '/m'+(to.path=='/'?'': to.path)});
  }else{
	   next(); 
  }
 

})



